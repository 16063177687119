import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

import './postTemplate.scss'
import moment from 'moment'

export default function Template({ data }) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const formattedDate = moment(frontmatter.date).format('YYYY-MM-DD hh:mm')
  return (
    <Layout>
      <article className="post-container">
        <div className="post-row">
          <span className="post-title">{frontmatter.title}</span>
          <span className="post-create-date">
            <time datatype={formattedDate}>
              <i>Posted: {formattedDate}</i>
            </time>
          </span>
        </div>
        <hr />
        <p className="post-description">
          <i>Description: {frontmatter.description}</i>
        </p>
        <div
          className="post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </article>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        date
        description
      }
    }
  }
`
